<template>
  <div>
    <ul class="biz-breadcrumb">
      <li>会費請求書</li>
    </ul>
    <div class="page">
      <div class="page-title">
        <h1>請求書一覧</h1>
      </div>
      <div class="card biz-primary mt-4">
        <div class="card-header">
          ビズハイウェイハイウェイの会費請求書一覧です。
        </div>
        <div class="card-body">
          <div class="p-2 bg-white">
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th class="text-center">請求書No.</th>
                  <th class="text-center">請求書明細</th>
                  <th class="text-center">合計</th>
                  <th class="text-center">振替日</th>
                  <th class="text-center">支払状況</th>
                </tr>
              </thead>
              <tbody>
                <tr v-bind:key="invoice.id" v-for="(invoice) in invoices">
                  <td class="text-center">{{invoice.number}}</td>
                  <td><a :href="invoice.url">{{invoice.startDate + '～' + invoice.endDate + 'ビズハイウェイ会費'}}</a></td>
                  <td>{{invoice.totalFee}}</td>
                  <td>{{invoice.transferDate}}</td>
                  <td class="text-center">{{invoice.status}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">

</style>

<script>

  export default {
    props: [],
    components: { },
    data: () => ({
      invoices: [
        {
          number: '00000100',
          startDate: '2020/01',
          endDate: '2020/06',
          totalFee: '￥52,800',
          transferDate: '2019/12/25',
          status: '支払い済み',
          url: '#'
        },
        {
          number: '00000305',
          startDate: '2020/07',
          endDate: '2020/12',
          totalFee: '￥52,800',
          transferDate: '2020/06/25',
          status: '支払い済み',
          url: '#'
        },
      ]
    })
  }
</script>